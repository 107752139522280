import Icon from './Icon'
import ReactModal from 'react-modal'
import Trans from './Trans'

ReactModal.setAppElement('#__next')

const Modal = ({ isOpen, close, disclaimer, children, alert = true, classes = '' }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={close}
      overlayClassName="modal__overlay"
      className={'modal__content ' + classes}
    >
      <button className="close" onClick={close}>
        <Icon id="close" />
      </button>
      {alert && (
        <p className="title">
          <Trans chiave="Attenzione" key={alert} />
        </p>
      )}
      {disclaimer && (
        <p className="disclaimer">
          <Trans chiave={disclaimer} />
        </p>
      )}
      {children}
    </ReactModal>
  )
}

export default Modal
