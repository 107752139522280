import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Modal from './Modal'

function PopupCommerciale() {
  const { menu } = useSelector(state => state.menu)
  const popup = menu.find(menu => menu.key === 'popup')

  if (!popup) return null

  const [show, setShow] = useState(false)

  function init() {
    if (!localStorage.getItem(`popup-${popup.name}-${popup.alias}`)) {
      setShow(true)
    }
  }

  function close() {
    localStorage.setItem(`popup-${popup.name}-${popup.alias}`, true)
    setShow(false)
  }

  useEffect(() => {
    setTimeout(() => {
      init()
    }, 3000)
  }, [])

  return (
    <Modal isOpen={show} alert={false} close={close}>
      <div className="popup">
        <img className="popup__image" src={popup.image} alt={popup.alias} />
        <h2 className="popup__title">{popup.alias}</h2>
        <p className="popup__disclaimer">{popup.name}</p>
        <a
          className="button button--primary"
          href={popup.final_url}
          target={popup.blank ? '_blank' : ''}
          onClick={close}
        >
          {popup.description}
        </a>
      </div>
    </Modal>
  )
}

export default PopupCommerciale
